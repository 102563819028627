@import url("https://fonts.cdnfonts.com/css/ds-digital");

body {
  height: 100dvh;
  width: 100vw;
  margin: 0;
  background-image: url("./assets/img/Background.webp");
  background-size: contain;
  font-family: "univia-pro", sans-serif;
  font-weight: 500;
  font-style: normal;
}
div#game-over {
  /* A fullscreen red overlay */
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(255, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 3em;
  color: white;
  z-index: 9998;
}

body.textbox_open {
  background-image: url("./assets/img/Background_textbox.webp");
  background-size: contain;
  background-repeat: no-repeat;
}
button {
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 15px;
  padding-right: 15px;
  font-size: 20px;
  display: inline-block;
  text-align: center;
  background-color: #011d3f;
  color: white;
  font-family: "univia-pro", sans-serif;
  font-weight: 500;
  font-style: normal;
}
div#help {
  background-image: url("./assets/img/Help_Button.webp");
  background-size: cover;
  position: absolute;
  right: 40px;
  top: 45px;
  width: 100px;
  height: 100px;
}
div#help-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9997;
}
button#modal-close-button {
  cursor: pointer;
  background-color: #011d3f;
  color: white;
  border: none;
  font-size: 20px;
  position: absolute;
  top: 10px;
  right: 10px;
}
div#help-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  background-color: #011d3f;
  padding: 20px;
  border: 1px solid white;
  z-index: 9998;
}
div#fullscreen-button {
  z-index: 9999;
  position: absolute;
  top: 10;
  right: 10;
}
div.game-1-container {
  margin-top: 125px;
}
div.progression-bar {
  position: fixed;
  bottom: 5%;
  left: 5%;
  width: 1200px;
  height: 100px;
  background-image: url("./assets/img/Progression_bar.webp");
  background-repeat: no-repeat;
  background-size: contain;
}
div.game-status {
  /* height: 66px;
  width: 375px; */
  height: 38px;
  width: 228px;
}
div.game-status {
  opacity: 0.5;
}
div.game-1 {
  background-color: #2c94e3;
  position: absolute;
  left: 36px;
  bottom: 29px;
  /* bottom: 66px;
  left: 80px; */
}
div.game-2 {
  background-color: #44a947;
  position: absolute;
  bottom: 29px;
  left: 317px;
}
div.game-3 {
  background-color: #75ecf8;
  position: absolute;
  bottom: 29px;
  left: 599px;
}
div.game-4 {
  background-color: #ffe62e;
  position: absolute;
  bottom: 29px;
  right: 90px;
}
div.game-status.completed {
  opacity: 1;
}
#countdown {
  top: 20px;
  left: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 200px;
  width: 200px;
  text-align: center;
  background: url("./assets/img/Timer_cirkel.webp");
  background-size: contain;
}

#countdown-number {
  font-size: 40px;
  color: #8ec303;
  display: inline-block;
  line-height: 160px;
}

#countdown svg {
  position: absolute;
  width: 187px;
  height: 187px;
  transform: rotateY(-180deg) rotateZ(-90deg);
}

#countdown svg circle {
  stroke-dasharray: 487px;
  stroke-dashoffset: 0px;
  stroke-linecap: round;
  stroke-width: 9px;
  stroke: #8ec303;
  fill: none;
}

div#video-modal {
  border-radius: 25px;
  overflow: hidden;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
}
div#backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9998;
}

button#play-button,
button#play-again-button {
  background: #011d3f;
  border: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  border-radius: 5px;
  display: flex;
  justify-content: center;
}
button#play-button svg,
button#play-again-button svg {
  width: 50px;
  height: 50px;
  fill: white;
}

/* Game 1 styling */
div.g1_dropzones {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90%;
  max-width: 100%;
  padding-left: 5%;
  padding-right: 5%;
  gap: 20px;
}
div.g1_dropzone {
  display: flex;
  justify-content: center;
  height: 50px;
  width: 200px;
  border: 2px solid white;
}
ul#g1_dragzone {
  max-width: 100%;
  padding-left: 5%;
  padding-right: 5%;
  display: flex;
  gap: 20px;
  justify-content: center;
}

ul#g1_dragzone li,
div.g1_dropzone li {
  padding: 10px;
  font-size: 18px;
  display: inline-block;
  text-align: center;
}

ul#g1_dragzone li {
  background-color: #f5f5f5;
}

div.g1_buttons {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 20px;
}

/* Game 2 styling */
div.game-2-container {
  margin-top: -100px;
  font-size: 1.5em;
  color: white;
  display: flex;
  justify-content: center;
  align-items: start;
  height: 100dvh;
  width: 100dvw;
}
div.g2_question_container {
  padding-top: 40px;
  font-family: "univia-pro", sans-serif;
  font-weight: 500;
  font-style: normal;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 60%;
}
div.g2_question_container p,
div.g2_question_container ol {
  color: #73b1f7;
}
div.g2_question_container li::marker {
  color: #aeff00;
}
div.g2_question_container span {
  color: #2c527e;
  margin-left: 50px;
}
div.g2_buttons {
  position: absolute;
  bottom: 29%;
  right: 10%;
}
div#g2-color-lists {
  display: flex;
  gap: 20;
}

/* Game 3 styling */
.memory-game {
  position: absolute;
  top: 18%;
  left: 20%;
  height: 604px;
  perspective: 755px;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 4px;
}

.memory-game .memory-card {
  margin-left: -50px;
}

.memory-card {
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 1);
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 216px;
  height: 192px;
  position: relative;
  transform: scale(1);
  transform-style: preserve-3d;
  transition: transform 0.5s;
}

.memory-card.flip span {
  transform: rotateY(180deg);
  z-index: 10;
  font-size: 1.5em;
}

.memory-card[data-color="yellow"] span {
  color: yellow;
}

.memory-card[data-color="red"] span {
  color: red;
}

.memory-card[data-color="blue"] span {
  color: blue;
}

.memory-card[data-color="green"] span {
  color: green;
}

/* Apply translation to specific cards */
.memory-card:nth-child(2),
.memory-card:nth-child(4) {
  transform: translateY(53%);
}

.memory-card:nth-child(6),
.memory-card:nth-child(8) {
  transform: translateY(-50%);
}

/* Ensure scale on active state */
.memory-card:active {
  transform: scale(0.97);
  transition: transform 0.2s;
}

/* Combine the flip and existing translations */
.memory-card.flip:nth-child(2),
.memory-card.flip:nth-child(4) {
  transform: translateY(53%) rotateY(180deg);
}

.memory-card.flip:nth-child(6),
.memory-card.flip:nth-child(8) {
  transform: translateY(-50%) rotateY(180deg);
}

.memory-card.flip {
  z-index: 9;
  transform: rotateY(180deg);
}

.memory-card img {
  width: 100%;
  height: 100%;
}

.front-face,
.back-face {
  width: 100%;
  height: 100%;
  position: absolute;
  border-radius: 5px;
  background: transparent;
  backface-visibility: hidden;
}

.front-face {
  transform: rotateY(180deg);
}

.back-face {
  z-index: 11;
}
button#game-3-next {
  font-size: 1.5em;
  font-weight: bold;
  position: absolute;
  bottom: 20%;
  right: 10%;
  padding: 10px;
  border-radius: 10%;
}
div#game-4-button-container {
  gap: 40;
  display: flex;
  align-items: center;
}
div#game-4-buttons {
  z-index: 9998;
}

/* Game 4 styling */
.game-4-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 900px;
  height: 600px;
}
.game-4-container #bomb {
  position: relative;
  width: 750px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
div#bomb-timer {
  font-family: "DS-Digital", sans-serif;
  position: absolute;
  z-index: 9999;
  top: 47%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 3em;
  color: red;
}
div#reset {
  position: absolute;
  bottom: 50px;
  right: 15px;
}

.game-4-container .wire,
.cut_wire {
  width: 300px;
}

.cut_wire {
  display: none;
}

.game-4-container #blue_wire,
#blue_wire_cut {
  position: absolute;
  bottom: 45%;
  left: 2%;
}
.game-4-container #yellow_wire,
#yellow_wire_cut {
  position: absolute;
  bottom: 52%;
  left: 74%;
}
.game-4-container #cyan_wire,
#cyan_wire_cut {
  position: absolute;
  bottom: 13%;
  left: 74%;
}
.game-4-container #green_wire,
#green_wire_cut {
  position: absolute;
  bottom: 28%;
  left: -1%;
}

.game-4-container #scissors {
  z-index: 9998;
}
