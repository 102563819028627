@import "https://fonts.cdnfonts.com/css/ds-digital";
body {
  background-image: url("Background.2a923d44.webp");
  background-size: contain;
  width: 100vw;
  height: 100dvh;
  margin: 0;
  font-family: univia-pro, sans-serif;
  font-style: normal;
  font-weight: 500;
}

div#game-over {
  color: #fff;
  z-index: 9998;
  background-color: #ff000080;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  font-size: 3em;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
}

body.textbox_open {
  background-image: url("Background_textbox.2d4563a0.webp");
  background-repeat: no-repeat;
  background-size: contain;
}

button {
  text-align: center;
  color: #fff;
  background-color: #011d3f;
  padding: 5px 15px;
  font-family: univia-pro, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  display: inline-block;
}

div#help {
  background-image: url("Help_Button.63fbd29d.webp");
  background-size: cover;
  width: 100px;
  height: 100px;
  position: absolute;
  top: 45px;
  right: 40px;
}

div#help-overlay {
  z-index: 9997;
  background-color: #00000080;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
}

button#modal-close-button {
  cursor: pointer;
  color: #fff;
  background-color: #011d3f;
  border: none;
  font-size: 20px;
  position: absolute;
  top: 10px;
  right: 10px;
}

div#help-modal {
  color: #fff;
  z-index: 9998;
  background-color: #011d3f;
  border: 1px solid #fff;
  padding: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

div#fullscreen-button {
  z-index: 9999;
  position: absolute;
  top: 10px;
  right: 10px;
}

div.game-1-container {
  margin-top: 125px;
}

div.progression-bar {
  background-image: url("Progression_bar.2b86d4b9.webp");
  background-repeat: no-repeat;
  background-size: contain;
  width: 1200px;
  height: 100px;
  position: fixed;
  bottom: 5%;
  left: 5%;
}

div.game-status {
  opacity: .5;
  width: 228px;
  height: 38px;
}

div.game-1 {
  background-color: #2c94e3;
  position: absolute;
  bottom: 29px;
  left: 36px;
}

div.game-2 {
  background-color: #44a947;
  position: absolute;
  bottom: 29px;
  left: 317px;
}

div.game-3 {
  background-color: #75ecf8;
  position: absolute;
  bottom: 29px;
  left: 599px;
}

div.game-4 {
  background-color: #ffe62e;
  position: absolute;
  bottom: 29px;
  right: 90px;
}

div.game-status.completed {
  opacity: 1;
}

#countdown {
  text-align: center;
  background: url("Timer_cirkel.7425f216.webp") 0 0 / contain;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 200px;
  display: flex;
  position: relative;
  top: 20px;
  left: 20px;
}

#countdown-number {
  color: #8ec303;
  font-size: 40px;
  line-height: 160px;
  display: inline-block;
}

#countdown svg {
  width: 187px;
  height: 187px;
  position: absolute;
  transform: rotateY(-180deg)rotateZ(-90deg);
}

#countdown svg circle {
  stroke-dasharray: 487;
  stroke-dashoffset: 0;
  stroke-linecap: round;
  stroke-width: 9px;
  stroke: #8ec303;
  fill: none;
}

div#video-modal {
  z-index: 9999;
  border-radius: 25px;
  position: absolute;
  top: 50%;
  left: 50%;
  overflow: hidden;
  transform: translate(-50%, -50%);
}

div#backdrop {
  z-index: 9998;
  background-color: #00000080;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
}

button#play-button, button#play-again-button {
  cursor: pointer;
  background: #011d3f;
  border: none;
  border-radius: 5px;
  justify-content: center;
  display: flex;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

button#play-button svg, button#play-again-button svg {
  fill: #fff;
  width: 50px;
  height: 50px;
}

div.g1_dropzones {
  justify-content: center;
  align-items: center;
  gap: 20px;
  max-width: 100%;
  height: 90%;
  padding-left: 5%;
  padding-right: 5%;
  display: flex;
}

div.g1_dropzone {
  border: 2px solid #fff;
  justify-content: center;
  width: 200px;
  height: 50px;
  display: flex;
}

ul#g1_dragzone {
  justify-content: center;
  gap: 20px;
  max-width: 100%;
  padding-left: 5%;
  padding-right: 5%;
  display: flex;
}

ul#g1_dragzone li, div.g1_dropzone li {
  text-align: center;
  padding: 10px;
  font-size: 18px;
  display: inline-block;
}

ul#g1_dragzone li {
  background-color: #f5f5f5;
}

div.g1_buttons {
  justify-content: center;
  gap: 20px;
  margin-top: 20px;
  display: flex;
}

div.game-2-container {
  color: #fff;
  justify-content: center;
  align-items: start;
  width: 100dvw;
  height: 100dvh;
  margin-top: -100px;
  font-size: 1.5em;
  display: flex;
}

div.g2_question_container {
  flex-direction: column;
  justify-content: center;
  width: 60%;
  padding-top: 40px;
  font-family: univia-pro, sans-serif;
  font-style: normal;
  font-weight: 500;
  display: flex;
}

div.g2_question_container p, div.g2_question_container ol {
  color: #73b1f7;
}

div.g2_question_container li::marker {
  color: #aeff00;
}

div.g2_question_container span {
  color: #2c527e;
  margin-left: 50px;
}

div.g2_buttons {
  position: absolute;
  bottom: 29%;
  right: 10%;
}

div#g2-color-lists {
  gap: 20px;
  display: flex;
}

.memory-game {
  perspective: 755px;
  grid-template-columns: repeat(5, 1fr);
  gap: 4px;
  height: 604px;
  display: grid;
  position: absolute;
  top: 18%;
  left: 20%;
}

.memory-game .memory-card {
  margin-left: -50px;
}

.memory-card {
  text-shadow: 1px 1px 2px #000;
  text-align: center;
  transform-style: preserve-3d;
  justify-content: center;
  align-items: center;
  width: 216px;
  height: 192px;
  transition: transform .5s;
  display: flex;
  position: relative;
  transform: scale(1);
}

.memory-card.flip span {
  z-index: 10;
  font-size: 1.5em;
  transform: rotateY(180deg);
}

.memory-card[data-color="yellow"] span {
  color: #ff0;
}

.memory-card[data-color="red"] span {
  color: red;
}

.memory-card[data-color="blue"] span {
  color: #00f;
}

.memory-card[data-color="green"] span {
  color: green;
}

.memory-card:nth-child(2), .memory-card:nth-child(4) {
  transform: translateY(53%);
}

.memory-card:nth-child(6), .memory-card:nth-child(8) {
  transform: translateY(-50%);
}

.memory-card:active {
  transition: transform .2s;
  transform: scale(.97);
}

.memory-card.flip:nth-child(2), .memory-card.flip:nth-child(4) {
  transform: translateY(53%)rotateY(180deg);
}

.memory-card.flip:nth-child(6), .memory-card.flip:nth-child(8) {
  transform: translateY(-50%)rotateY(180deg);
}

.memory-card.flip {
  z-index: 9;
  transform: rotateY(180deg);
}

.memory-card img {
  width: 100%;
  height: 100%;
}

.front-face, .back-face {
  backface-visibility: hidden;
  background: none;
  border-radius: 5px;
  width: 100%;
  height: 100%;
  position: absolute;
}

.front-face {
  transform: rotateY(180deg);
}

.back-face {
  z-index: 11;
}

button#game-3-next {
  border-radius: 10%;
  padding: 10px;
  font-size: 1.5em;
  font-weight: bold;
  position: absolute;
  bottom: 20%;
  right: 10%;
}

div#game-4-button-container {
  align-items: center;
  gap: 40px;
  display: flex;
}

div#game-4-buttons {
  z-index: 9998;
}

.game-4-container {
  width: 900px;
  height: 600px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.game-4-container #bomb {
  width: 750px;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

div#bomb-timer {
  z-index: 9999;
  color: red;
  font-family: DS-Digital, sans-serif;
  font-size: 3em;
  position: absolute;
  top: 47%;
  left: 50%;
  transform: translate(-50%, -50%);
}

div#reset {
  position: absolute;
  bottom: 50px;
  right: 15px;
}

.game-4-container .wire, .cut_wire {
  width: 300px;
}

.cut_wire {
  display: none;
}

.game-4-container #blue_wire, #blue_wire_cut {
  position: absolute;
  bottom: 45%;
  left: 2%;
}

.game-4-container #yellow_wire, #yellow_wire_cut {
  position: absolute;
  bottom: 52%;
  left: 74%;
}

.game-4-container #cyan_wire, #cyan_wire_cut {
  position: absolute;
  bottom: 13%;
  left: 74%;
}

.game-4-container #green_wire, #green_wire_cut {
  position: absolute;
  bottom: 28%;
  left: -1%;
}

.game-4-container #scissors {
  z-index: 9998;
}

/*# sourceMappingURL=index.3edc6d04.css.map */
